<style scoped>
.search-list {
  margin: 20px 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.video-container {
  width: 200px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
}
.image {
  border-radius: 14px 14px 0 0;
  min-height: 200px;
  max-height: 200px;
}
.video-desc {
  height: 60px;
  background: #12141e;
  border-radius: 0 0 14px 14px;
  color: #f9f5f5;
  padding: 10px;
  font-size: 12px;
}
.video-desc .title {
  margin-bottom: 20px;
}
</style>
<template>
  <div class="search-list">
    <div
      class="video-container"
      v-for="(video, index) in list"
      :key="index"
      @click="$emit('handle-click', video)"
    >
      <el-link type="info">
        <img :src="video.cszPath" class="image" width="200px" />
        <div class="video-desc">
          <div class="title">{{ video.video_title }}</div>
          <div class="client" @click="$emit('go-to-detail', video.clientId)">
            @{{ video.clientName }}
          </div>
        </div>
      </el-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: { type: Array },
  },
};
</script>