<style scoped>
.el-main {
  padding: 0;
}
.container {
  margin: 40px 80px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}
.work-info::after {
  content: none !important;
  height: 0;
  text-decoration: none;
}
.work-info .image {
  width: 165px;
  height: 165px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.work-info .label {
  text-align: center;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header />
      <div class="container">
        <div class="row">
          <el-link class="work-info" @click="goToDetail(1)">
            <img src="../assets/work-backend.png" class="image" alt="" />
            <div class="label">后端开发</div>
          </el-link>
          <el-link class="work-info" @click="goToDetail(2)">
            <img src="../assets/work-fontend.png" class="image" alt="" />
            <div class="label">前端开发</div>
          </el-link>
          <el-link class="work-info" @click="goToDetail(3)">
            <img src="../assets/work-mobile.png" class="image" alt="" />
            <div class="label">移动端开发</div>
          </el-link>
        </div>
        <div class="row">
          <el-link class="work-info" @click="goToDetail(4)">
            <img src="../assets/work-bunisiness.png" class="image" alt="" />
            <div class="label">运营/行政</div>
          </el-link>
          <el-link class="work-info" @click="goToDetail(5)">
            <img src="../assets/work-designer.png" class="image" alt="" />
            <div class="label">设计</div>
          </el-link>
          <el-link class="work-info" @click="goToDetail(6)">
            <img src="../assets/work-marketing.png" class="image" alt="" />
            <div class="label">市场</div>
          </el-link>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";

export default {
  components: { Header },
  methods: {
    goToDetail(index) {
      this.$router.push({
        path: "/join/detail",
        query: { index },
      });
    },
  },
};
</script>