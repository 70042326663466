<style scoped>
.el-main {
  padding: 0;
}
.container {
  padding: 20px 40px;
}
.title {
  color: #0d0d0d;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}
.info {
  display: flex;
  justify-content: space-between;
}
.video-container {
  width: 22%;
  border-radius: 14px;
  min-height: 155px;
}
.video-container .video {
  width: 100%;
  border-radius: 14px;
}
.first-part {
  width: 100%;
  height: 250px;
  border-radius: 14px;
  border: 1px solid lightgray;
}
.third-part .video {
  width: 100%;
}
.fouth-part .info {
  justify-content: flex-start;
  font-size: 18px;
  line-height: 40px;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header />
      <div class="container">
        <div class="first-part"><img src="../assets/11.png" class="first-part" alt="" /></div>
        <div class="second-part">
          <div class="title">企业文化</div>
          <div class="info">
            <div class="video-container">
              <video
                src="https://file.shuke.shop/UploadImage/mv/mv1.mp4"
                class="video"
                controls
                muted="muted"
                autoplay
                controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
              ></video>
            </div>
            <div class="video-container">
              <video
                src="https://file.shuke.shop/UploadImage/mv/mv2.mp4"
                class="video"
                controls
                muted="muted"
                autoplay
                controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
              ></video>
            </div>
            <div class="video-container">
              <video
                src="https://file.shuke.shop/UploadImage/mv/mv3.mp4"
                class="video"
                controls
                autoplay
                muted="muted"
                controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
              ></video>
            </div>
            <div class="video-container">
              <video
                src="https://file.shuke.shop/UploadImage/mv/mv4.mp4"
                class="video"
                controls
                autoplay
                muted="muted"
                controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
              ></video>
            </div>
          </div>
        </div>
        <div class="third-part">
          <div class="title">加入熟客shuke</div>
          <div class="info">
            <div class="video"></div>
          </div>
        </div>
        <div class="fouth-part">
          <div class="title">联系我们</div>
          <div class="info">
            办公地址: 上海市闵行区联航路1505弄1号楼1307室 13918970013<br />
            入驻商家咨询：shaohz@hongzengxinxi.com<br />
            个人咨询：shaohz1@hongzengxinxi.com<br />
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";

export default {
  components: { Header },
};
</script>
