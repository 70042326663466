<style scoped>
.header {
  background: #3c3c3c;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.input-box {
  border-radius: 10px 0 0 10px;
  background: #fff;
  color: #000;
  padding-left: 10px;
  height: 40px;
  width: 400px;
  line-height: 40px;
}
.search-button {
  width: fit-content;
  color: #999999;
  background: #fff;
  padding: 0 10px;
  border-radius: 0 10px 10px 0;
  height: 40px;
}
.button {
  margin-left: auto;
}
.button,
.button::v-deep span {
  width: 120px;
  height: 40px;
}
::v-deep .el-input__inner {
  height: 40px;
  border: 0;
}
::v-deep .el-input__inner,
::v-deep .el-input__clear {
  line-height: 40px;
}
.el-main {
  padding: 0;
  position: relative;
}
.company-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 33%;
}
.demonstration {
  font-weight: 600;
  font-size: 20px;
}

</style>
<template>

  <el-container>
    <el-header class="header">
      <div
        class="empty"
        style="width: 20px; height: 40px; margin-right: auto"
      ></div>
      <div class="input-container">
        <el-input
          class="input-box"
          v-model="input"
          placeholder="请输入内容"
          clearable
        ></el-input>
        <el-button class="search-button" type="text" icon="el-icon-search"
        >搜索</el-button
        >
      </div>
      <el-button type="danger" class="button" plain>登录</el-button>
    </el-header>
    <el-main>
      <div class="company-container">
        <div class="block" scale>
<!--          <img src="../assets/1.png" width="50%" />-->
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/1.png')"
            :fit="contain"
            :preview-src-list="[require('../assets/1.png')]"
            >
          </el-image>
          <span class="demonstration">高新技术企业证书</span>
        </div>
        <div class="block" >
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/2.png')"
            :preview-src-list="[require('../assets/2.png')]">
          </el-image>
          <span class="demonstration">对外贸易经营者备案</span>
        </div>
        <div class="block" >
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/3.png')"
            :preview-src-list="[require('../assets/3.png')]">
          </el-image>
          <span class="demonstration">海关进出口货物收发货人备案回执</span>
        </div>
        <div class="block">
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/4.png')"
            :preview-src-list="[require('../assets/4.png')]">
          </el-image>
          <span class="demonstration">富掌柜市场开拓奖</span>
        </div>
        <div class="block" >
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/5.png')"
            :preview-src-list="[require('../assets/5.png')]">
          </el-image>
          <span class="demonstration">富掌柜授权证书</span>
        </div>
        <div class="block" >
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/6.png')"
            :preview-src-list="[require('../assets/6.png')]">
          </el-image>
          <span class="demonstration">收单外包服务机构备案回执</span>
        </div>
        <div class="block" >
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/7.png')"
            :preview-src-list="[require('../assets/7.png')]">
          </el-image>
          <span class="demonstration">营业执照</span>
        </div>
        <div class="block">
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/8.png')"
            :preview-src-list="[require('../assets/8.png')]">
          </el-image>
          <span class="demonstration">富友荣誉证书</span>
        </div>
        <div class="block">
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/10.png')"
            :preview-src-list="[require('../assets/10.png')]">
          </el-image>
          <span class="demonstration">富友奖杯</span>
        </div>
        <div class="block">
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/9.png')"
            :preview-src-list="[require('../assets/9.png')]">
          </el-image>
          <span class="demonstration">增值电信许可证</span>
        </div>
        <div class="block">
          <el-image
            style="width: 200px; height: 150px"
            :src="require('../assets/12.png')"
            :preview-src-list="[require('../assets/12.png')]">
          </el-image>
          <span class="demonstration">网络文化经营许可证</span>
        </div>

      </div>
    </el-main>

  </el-container>
</template>

<script>

export default {
  data() {
    return {
      // srcList: [
      //   require('../assets/1.png'),
      // ],
      srcList2: [
        require('../assets/2.png'),
      ]
    }
  }
}
</script>
