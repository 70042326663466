import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Framework7 from "framework7/framework7.esm.bundle";
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle";
Framework7.use(Framework7Vue);
import App from './App.vue'

import DesktopIndexPage from './pages/DesktopIndexPage.vue';
import DesktopMapViewPage from './pages/DesktopMapViewPage.vue';
import DesktopAboutPage from './pages/DesktopAboutPage.vue';
import DesktopCompanyPage from './pages/DesktopCompanyPage.vue';
import DesktopSearchPage from './pages/DesktopSearchPage.vue';
import DesktopClientPage from './pages/DesktopClientPage.vue';
import DesktopMyPage from './pages/DesktopMyPage.vue';
import DesktopShopPage from './pages/DesktopShopPage.vue';
import DesktopVideoPlayPage from './pages/DesktopVideoPlayPage.vue'
import DesktopJoinPage from './pages/DesktopJoinPage.vue'
import DesktopJoinDetailPage from './pages/DesktopJoinDetailPage.vue'
import DesktopFindMePage from './pages/DesktopFindMePage.vue'

Vue.use(VueRouter)

Vue.use(ElementUI);
Vue.config.productionTip = false

const routes = [
  { path: '/', component: DesktopIndexPage },
  { path: '/view', component: DesktopIndexPage },
  { path: '/map', component: DesktopMapViewPage },
  { path: '/about', component: DesktopAboutPage },
  { path: '/company', component: DesktopCompanyPage },
  { path: '/search', component: DesktopSearchPage },
  { path: '/client', component: DesktopClientPage },
  { path: '/my', compoanent: DesktopMyPage },
  { path: '/shop', component: DesktopShopPage },
  { path: '/play', component: DesktopVideoPlayPage },
  { path: '/join', component: DesktopJoinPage },
  { path: '/join/detail', component: DesktopJoinDetailPage },
  { path: '/find', component: DesktopFindMePage }
]


const router = new VueRouter({ routes })

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
