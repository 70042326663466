<style scoped>
.el-main {
  position: relative;
  padding: 0;
}
.tab-container {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-top: 20px;
  padding: 0 80px;
  text-align: center;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header />
      <el-tabs class="tab-container" v-model="activeName">
        <el-tab-pane
          label="浏览"
          name="1"
          v-infinite-scroll="fetchVideo"
          infinite-scroll-delay="500"
          infinite-scroll-disabled="disabled"
        >
          <Video-list
            :list="list"
            @handle-click="playItem"
            @go-to-detail="goToClientPage"
          />
        </el-tab-pane>
        <el-tab-pane
          label="收藏"
          name="2"
          v-infinite-scroll="fetchVideo"
          infinite-scroll-delay="500"
          infinite-scroll-disabled="disabled"
        >
          <Video-list
            :list="list"
            @handle-click="playItem"
            @go-to-detail="goToClientPage"
          />
        </el-tab-pane>
        <el-tab-pane
          label="评论"
          name="3"
          v-infinite-scroll="fetchVideo"
          infinite-scroll-delay="500"
          infinite-scroll-disabled="disabled"
        >
          <Video-list
            :list="list"
            @handle-click="playItem"
            @go-to-detail="goToClientPage"
          />
        </el-tab-pane>
        <el-tab-pane label="商家控制台" name="4">
          暂不支持查看，请去APP查看
        </el-tab-pane>
        <el-tab-pane label="订单" name="5">
          暂不支持查看，请去APP查看
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";
import VideoList from "../components/VideoList.vue";

export default {
  components: { Header, VideoList },
  data() {
    return {
      activeName: "1",
      total: 0,
      page: 1,
      list: [],
    };
  },
  watch: {
    activeName(newVal) {
      if (Number(newVal) < 4) {
        this.list = [];
        this.page = 1;
        this.total = 0;
        this.fetchVideo();
      }
    },
  },
  computed: {
    disabled() {
      return this.total / 20 <= this.page;
    },
  },
  mounted() {
    this.fetchVideo();
  },
  methods: {
    async fetchVideo() {
      if (!this.$root.$children[0].isLogin) {
        return;
      }
      if (this.total / 20 > this.page) {
        this.page += 1;
      }
      const formData = new FormData();
      formData.append(
        "title_id",
        { 1: 1000, 2: 1001, 3: 1002 }[Number(this.activeName)]
      );
      formData.append("userId", this.$root.$children[0].userInfo.xh);
      // formData.append("userId", 110);
      // formData.append("title_id", 17);
      formData.append("page", this.page);
      formData.append("rows", 20);
      formData.append("type", 1);
      const response = await fetch("SignController/getjckdnr", {
        method: "post",
        body: formData,
      });
      const jsonData = await response.json();
      console.log(jsonData);
      this.list = [...this.list, ...(jsonData.video_json || [])];
      this.total = jsonData.total || 0;
    },
    playItem(item) {
      console.log(item);
      this.$router.push({
        path: "/play",
        query: { item },
      });
    },
    goToClientPage(id) {
      this.$router.push({
        path: "/client",
        query: { clientId: id },
      });
    },
  },
};
</script>