<template>
  <div id="app">
    <mobile-page v-if="isPhone" />
    <desktop-page v-else></desktop-page>
  </div>
</template>

<script>
import DesktopPage from "./pages/DesktopPage.vue";
import MobilePage from "./pages/MobilePage.vue";

export default {
  name: "App",
  components: { MobilePage, DesktopPage },
  data() {
    return {
      isLogin: false,
      userInfo: {},
    };
  },
  computed: {
    isPhone() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  mounted() {
    const loginSuccess = localStorage.getItem("isLogin") || "false";
    const userInfo = localStorage.getItem("userInfo") || "{}";
    this.isLogin = loginSuccess === "true";
    this.userInfo = JSON.parse(userInfo);
  },
  methods: {},
};
</script>
<style scoped>
#app {
  min-width: 1200px;
  overflow: auto;
}
</style>
<style>
body {
  overflow: auto;
  /* overflow-y; */
}
.ios body,
.android body {
  overflow: hidden;
}
.ios #app {
  min-width: initial;
  overflow: hidden;
}
</style>
