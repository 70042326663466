<style scoped>
.el-main {
  padding: 0;
}
.container {
  padding: 20px 40px;
}
#findMap {
  height: 60vh;
}
.title {
  color: #0d0d0d;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}
.info {
  display: flex;
  justify-content: space-between;
}

.fouth-part .info {
  justify-content: flex-start;
  font-size: 18px;
  line-height: 40px;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header />
      <div class="container">
        <div id="findMap"></div>
        <div class="fouth-part">
          <div class="title">联系我们</div>
          <div class="info">
            办公地址: 上海市闵行区联航路1505弄1号楼1307室 13918970013<br />
            入驻商家咨询：shaohz@hongzengxinxi.com<br />
            个人咨询：shaohz1@hongzengxinxi.com<br />
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";

export default {
  components: { Header },
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.map = new window.AMap.Map("findMap", {
        zoom: 12,
        center: [121.517382, 31.076256],
        resizeEnable: true,
      });
      const marker = new window.AMap.Marker({
        position: new window.AMap.LngLat(121.517382, 31.076256),
        title: "上海红增信息技术有限公司",
      });
      marker.setLabel({
        direction: "top",
        offset: new window.AMap.Pixel(0, 0),
        content: "上海红增信息技术有限公司",
      });
      this.map.add(marker);
      window.AMap.plugin("AMap.Geolocation", () => {
        const geolocation = new window.AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 1000,
          buttonPosition: "RB",
          buttonOffset: new window.AMap.Pixel(10, 40),
          zoomToAccuracy: true,
        });
        this.map.addControl(geolocation);
      });
    },
  },
};
</script>
