<style scoped>
.el-main {
  padding: 0;
}
.container {
  padding: 20px 80px 20px 140px;
}
.title {
  font-size: 24px;
  font-weight: 700;
}
.title,
.desc {
  margin-bottom: 10px;
}
.label {
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
}
.inline {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.desc li {
  font-size: 16px;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header />
      <div class="container">
        <div class="title">{{ jobDetail.title }}</div>
        <div class="desc inline">
          <div class="label">城市:</div>
          上海市
        </div>
        <div class="desc inline">
          <div class="label">工作地址:</div>
          上海市闵行区联航路1505弄1号楼1307室
        </div>
        <div class="desc responsibility" v-if="jobDetail.responsibility">
          <div class="label">岗位职责:</div>
          <ul>
            <li
              class="work-list-item"
              v-for="(item, index) in jobDetail.responsibility"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="desc requirements" v-if="jobDetail.requirements">
          <div class="label">岗位要求:</div>
          <ul>
            <li
              class="work-list-item"
              v-for="(item, index) in jobDetail.requirements"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="desc">
          <div class="label">工作时间:</div>
          <ul>
            <li>上午9:00:00-12:00:00</li>
            <li>下午13:00:00-18:00:00</li>
          </ul>
        </div>
        <div class="desc">
          <div class="label">联系方式:</div>
          <ul>
            <li>邮箱：shaohz@hongzengxinxi.com</li>
            <li>电话：18702146858(同微信)</li>
            <li>面试地址：上海市闵行区联航路1505弄1号楼1307室</li>
          </ul>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";

export default {
  components: { Header },
  data() {
    return {
      showIndex: 1,
      detail: {
        1: {
          title: "JAVA开发工程师",
          requirements: [
            "全日制本科及以上学历学信网可查的,4年以上JAVA经验,2018年及之前毕业的",
            "熟悉JAVA开发语言、JAVA基础知识扎实,熟练JAVA数据结构、多线程编程",
            "掌握spring、springboot、springcloud技术框架",
            "熟悉数据库原理并熟练掌握相关优化方案,熟练掌握SQL语句,熟悉mysql数据库管理、开发、调优",
            "熟悉微服务、分布式系统原理",
            "熟悉vue等前端技术",
            "具备强烈的责任心，思路清晰，较强的学习能力和解决问题的能力，良好的需求归纳分析和文档编写能力",
          ],
        },
        2: {
          title: "前端开发工程师",
          requirements: [
            "全日制本科及以上学历学信网可查的,4年以上JAVA经验,2018年及之前毕业的",
            "熟悉JAVA开发语言、JAVA基础知识扎实,熟练JAVA数据结构、多线程编程",
            "掌握spring、springboot、springcloud技术框架",
            "熟悉数据库原理并熟练掌握相关优化方案,熟练掌握SQL语句,熟悉mysql数据库管理、开发、调优",
            "熟悉微服务、分布式系统原理",
            "熟悉vue等前端技术",
            "具备强烈的责任心，思路清晰，较强的学习能力和解决问题的能力，良好的需求归纳分析和文档编写能力",
          ],
        },
        3: {
          title: "移动端开发工程师",
          requirements: [
            "学信网可查全日制专科及以上学历(计算机相关专业),2019年及之前毕业,2年及以上小程序开发经验",
            "精通微信支付宝小程序开发，能够独立完成开发和部署任务",
            "前端技术基础扎实，有后端开发经验优先",
            "理解前端组件化开发思想,精通H5开发,精通JS或TS开发,精通ES6语法。能熟练使用vue或AngularJS主流前端开发框架",
            "熟悉常用设计模式,有基础的UI设计能力",
            "配合项目经理完成相关任务目标",
          ],
        },
        4: {
          title: "运营助理",
          requirements: [
            "大专及以上学历，有相关工作经验优先",
            "工作及时高效、条理性强、工作细心，有良好的组织协调能力及沟通能力",
            "为人正直，有亲和力，有良好的情绪管理能力，具备良好的职业心态和服务意识",
            "熟悉运用各种日常办公软件",
          ],
          responsibility: [
            "负责办公室日常办公室制度维护和管理",
            "协助完成公司行政及部门日常事务工作",
            "公司外来物品及总公司往返物品的识别、登记和分发工作",
            "外部往来、访问人员的登记、临时接待和管理",
            "做好材料收集、档案管理、快递收发等工作",
            "完成领导交办的其他工作",
          ],
        },
        5: {
          title: "UI设计师",
          requirements: [
            "美术、平面设计、视觉艺术设计相关专业,大专以上学历，有较强审美能力和美术功底",
            "有平面设计或展览项目设计相关经验优先,会简单c4d软件优先",
            "能高效完成主视觉和平面延展设计,精通各种平面设计软件,熟练使用Photoshop、lllustrator、Coreldraw或Indesign",
            "善于创意,有独立设计制作能力",
            "有良好的理解和沟通能力,工作态度积极认真,有良好的团队合作精神,责任心强",
          ],
          responsibility: [
            "负责公司各部门线上及线下画册、产品海报易拉宝、平台活动页面、专题页、产品banner、推广图、logo等平面设计、延展设计,准确把握公司产品及项目的设计需求",
            "负责展览.展示.展厅项目的平面设计,制作以及其它图文处理工作,可独立完成公司项目的创意设计提案及上级主管交付的其他设计工作,协助部门人员完成设计及美学方面的工作,根据客户需求及整体设计策略,提供创意及设计思路",
            "配合完成公司日常所需的设计工作",
          ],
        },
        6: {
          title: "市场营销",
          requirements: [
            "全日制本科及以上学历1-3年相关工作经验,电子商务、网络营销相关专业、从事销售或同类岗位的优先",
            "有责任心、性格开朗、具有良好的沟通能力及交际能力,处事灵活变通,有清晰的市场运营思路",
            "有经销渠道建设及管理经验、有较强的业务推进及执行能力",
            "具备一定的市场分析及总结能力,以目标为导向对所负责的市场进行整体规划布局,有代理市场开拓经验者优先,可自带代理商",
          ],
          responsibility: [
            "根据公司规划要求,因地制宜制订合理的渠道建设计划方案",
            "定期拜访区域客户,建立并维护良好的客情关系",
            "及时有效的反馈执行结果并修正实施方案,协助代理商进行市场开发和渗透工作",
            "通过拜访区域客户调研相关信息找寻合作代理商、进行代理商调研,收集、分析所辖区域市场的需求信息",
            "开发、维护所辖区域销售渠道资源(代理商、渠道商)",
            "完成领导临时交办的其他任务",
          ],
        },
      },
    };
  },
  computed: {
    jobDetail() {
      return this.detail[this.showIndex];
    },
  },
  mounted() {
    this.showIndex = this.$route.query.index || 1;
  },
};
</script>
